<script setup>
/* === i18n === */
const { locale } = useI18n()
const globalContentStore = useGlobalContentStore()
const { homeComponentId } = storeToRefs(globalContentStore)

/* === init === */
const { $directus, $readItems } = useNuxtApp()

const props = defineProps({
  identifier: {
    type: Number,
    default: null,
  },
  slug: {
    type: String,
    default: null,
  },
})

const config = useRuntimeConfig()

/* === fetch logic === */
let filterConfig

if (props.identifier) {
  filterConfig = {
    id: { _eq: props.identifier },
  }
} else if (props.slug) {
  filterConfig = {
    _and: [
      {
        translations: {
          slug: {
            _eq: props.slug,
          },
        },
      },
      {
        envs: { _contains: config.public.dataEnv },
      },
    ],
  }
}

const { data: rawData, error } = await useAsyncData('content', () => {
  return $directus().request(
    $readItems('content', {
      fields: [
        '*',
        'seo.*',
        {
          translations: [
            'languages_code',
            'title',
            'slug',
            'summary',
            'id',
            'items.id',
            'items.collection',
            'items.item.*.*',
            'items.item.items.image.*',
            'items.item.items.item.*',
            'items.item.items.item.image.*',
            'items.item.items.item.hero_image.*',
            'items.item.items.item.translations.*',
          ],
        },
      ],
      filter: filterConfig,
    })
  )
})

if (error.value) {
  showError(directusErrorConfig)
}

if (rawData.value.length === 0) {
  showError(pageIdentificationErrorConfig)
}

// used for i18n of slugs
const route = useRoute()
route.meta.contentSlugs = rawData.value[0].translations

/* === block logic === */
const page = computed(
  // Get the translation for the current locale, fallback to the first one
  () => {
    const data = rawData.value[0]

    if (data && data.translations) {
      return (
        data.translations.find((t) => t.languages_code === locale.value) ||
        data.translations[0]
      )
    } else {
      return { items: [] }
    }
  }
)

const components = {
  block_slider: resolveComponent('BlockSlider'),
  block_text: resolveComponent('BlockText'),
  block_embed: resolveComponent('BlockEmbed'),
  block_links: resolveComponent('BlockLinks'),
  block_list: resolveComponent('BlockList'),
}

/* === seo === */
useHead({ title: page.value.title })

const seo = rawData.value[0].seo

const seoTitle = page.value.title || ''
const seoDescription = page.value.summary || ''
const seoImage = seo?.og_image && useDirectusImgSrc(seo.og_image)

useSeoMeta({
  title: seoTitle,
  ogTitle: seoTitle,
  description: seoDescription,
  ogDescription: seoDescription,
  ogImage: seoImage,
  twitterCard: 'summary_large_image',
  robots: {
    index: Boolean(seo?.no_index),
    follow: Boolean(seo?.no_follow),
  },
})

useSchemaOrg([
  defineWebPage({
    name: seoTitle,
    description: seoDescription,
    image: seoImage,
    inLanguage: computed(() => locale.value),
  }),
])

// add schema.org article node if the page is not landing page
if (!props.identifier && props.identifier !== homeComponentId.value) {
  useSchemaOrg([
    defineArticle({
      headline: seoTitle,
      description: seoDescription,
      image: seoImage,
      inLanguage: computed(() => locale.value),
    }),
  ])
}
</script>

<template>
  <section>
    <div>
      <div v-for="block in page.items" :key="block.identifier">
        <component
          :is="components[block.collection]"
          v-if="components[block.collection]"
          :data="block.item"
        />
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
section {
  margin-bottom: 5rem;
}
</style>
