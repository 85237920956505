<script setup>
defineProps({
  item: {
    type: Object,
    required: true,
  },
  data: {
    type: Object,
    required: true,
  },
})

const imageWidth = 1500

function getAspectRatio(aspectRatio) {
  const [width, height] = aspectRatio.split('/') || [3, 1]
  return height / width
}

const searchQuery = ref('')
function search(event) {
  event.preventDefault()
  // based on routing:
  // - additonal entry in history
  // - component is not part of ais instance (no connectors available)
  useRouter().push({
    path: localePath('search'),
    query: {
      query: searchQuery.value,
    },
  })
}
const localePath = useLocalePath()
</script>
<template>
  <!-- eslint-disable vue/no-v-html -->
  <div
    :class="['swiper-slide', 'swiper-slide-' + item.type]"
    :style="{
      '--background-color': item.color_background || 'transparent',
      '--text-color': item.color_text || 'var(--color-text)',
    }"
  >
    <NuxtLink :to="item.link" :target="targetForURL(item.link)">
      <div class="swiper-item">
        <div v-if="item.image" class="swiper-image">
          <CaptionedImage
            :src="
              useDirectusImgSrc(
                item.image.id,
                imageWidth,
                Math.round(
                  (item.type === 'image-text' ? 1.4 : 1.0) *
                    imageWidth *
                    getAspectRatio(data.aspect_ratio)
                )
              )
            "
            :image="item.image"
          />
        </div>
        <div
          v-if="item.heading || item.summary || item.show_search"
          class="swiper-text"
        >
          <p v-if="item.category" class="swiper-category">
            {{ item.category }}
          </p>
          <h2
            v-if="item.heading"
            :class="[
              'swiper-heading',
              {
                'swiper-heading-long':
                  item.heading.length > 50 ||
                  item.is_long_heading ||
                  item.collection === 'institutions',
              },
            ]"
          >
            {{ item.heading }}
          </h2>
          <div v-if="item.show_search" class="swiper-searchbox">
            <form
              ref="searchForm"
              class="swiper-input-wrapper"
              @submit="search"
            >
              <input
                ref="queryInput"
                v-model="searchQuery"
                :placeholder="$t('search.inputPlaceholder')"
                class="searchbox-input"
                type="search"
                spellcheck="false"
              />
              <span class="submit-icon bi-search"></span>
            </form>
          </div>
          <p v-if="item.summary" class="swiper-summary">
            {{ item.summary }}
          </p>
          <p v-if="data.type === 'standard'" class="swiper-more">
            {{ item.call_to_action || $t('block.more') }}
          </p>
        </div>
      </div>
    </NuxtLink>
  </div>
</template>

<style lang="scss" scoped>
// Styles for all variations of sliders
.swiper-item {
  height: 100%;
  display: block;
  background-color: var(--background-color);
  box-sizing: border-box;
}

.swiper-text {
  color: var(--text-color);
  display: flex;
  gap: 1em;
  width: 100%;
  flex-direction: column;
  padding: 1rem 2rem;
  @include media('>=phone') {
    padding: 3rem;
  }
  text-align: center;
}
.swiper-category {
  @extend %font-sub-hl1;
  margin-bottom: 0.3em;
}

.swiper-heading {
  @extend %font-hl1;
  line-height: 1.1;
}

.swiper-summary {
  margin: 0.4em 0 auto;
  padding: 0 10vw;
  @extend %font-hl3;
}

.swiper-more {
  @extend %font-hl5;
  text-decoration: underline;
}

.swiper-searchbox {
  margin: auto;
  width: 100%;
  max-width: 40em;
  box-shadow: 0 0 1em rgba(0, 0, 0, 0.5);
  position: relative;
  .searchbox-input {
    @extend %searchbox-input;
    // Selector interference with the parent component
    &:focus-visible {
      outline-offset: 0;
    }
  }
  .submit-icon {
    pointer-events: none;
  }
  .submit-icon {
    position: absolute;
    font-size: 1.5em;
    line-height: 1;
    right: 0.5em;
    top: 50%;
    transform: translateY(-50%);
    color: var(--color-text);
  }
}
</style>
